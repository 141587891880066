import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../context/DataContext";
import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	ButtonGroup,
	Button,
	Div,
	FixedLayout,
	Spacing,
	Title,
	usePlatform,
	Platform,
	Caption,
	Footer,
	Link,
	CellButton,
	CardGrid,
	Card,
	RichCell,
	Image
} from "@vkontakte/vkui";
import { NavProp, GoFunctionProp, Zapiska } from "../types";
import { DEFAULT_VIEW_PANELS, HOME_PANEL_MODALS } from "../routes";
import {
	useParams,
	useRouteNavigator,
	//useEnableSwipeBack,
} from "@vkontakte/vk-mini-apps-router";

import { Icon20HelpOutline } from "@vkontakte/icons";

import "../App.css"

type CategoriesPanelProps = NavProp & GoFunctionProp;

export const Categories = ({ nav, go }: CategoriesPanelProps) => {
	const { cat, type }: any = useParams({ panel: nav }) ?? {};
	const { products, getProduct } = useContext(DataContext);
	//const { categories, getProduct } = useContext(DataContext);
	const [variation, setVariation] = useState<string>("");
	const platform = usePlatform();
	
	const isVKCOM = platform === Platform.VKCOM;
	//const isMobile = platform === Platform.ANDROID||Platform.IOS;

	const cats = localStorage.getItem('cats') ? JSON.parse(localStorage.getItem('cats')  || '[]') : []


	const routeNavigator = useRouteNavigator();

		 

	const SelectProduct = () => {
		const catsFiltered = cats.filter((item:any) => item.parent_id === 0);
		const itemCSS = isVKCOM ? {flex:"0 48%", margin:"1%"} : {}
		const catList = catsFiltered.map((value, index) => {
			
				return (
					<Card key={value.id} mode="outline-tint" style={itemCSS}>
						<RichCell
							style={{paddingLeft:8, paddingRight: 2}}
							before={<Image src={`/${value.id}.svg`} size={56} style={{padding:"5px"}} withBorder={false} />}
							text={<strong>{value.title}</strong>}
							multiline
							onClick={()=>{routeNavigator.push(`/${DEFAULT_VIEW_PANELS.CATEGORY}/${value.id}`);}}
						/>
					</Card>
				);
		});
		if (isVKCOM) {
			return (
 				<div style={{display:"flex", flexWrap:"wrap",  flexDirection:"row", alignContent:"stretch", alignItems: "stretch"}}>
{catList}
				</div>
			)
		} else {
		return (
			<CardGrid size="l">
				{catList}
			</CardGrid>
		)
		}
	}
	var note:Zapiska;
	note = {};

	

	return (
		<Panel id="categories" nav={nav}>
			<PanelHeader
				before={ (cat || type) &&
					<PanelHeaderBack
						onClick={() => {
							if (cat && !type && !variation) {
								routeNavigator.replace("/");
							} else if (cat && !type && variation) {
								setVariation("");
								routeNavigator.replace(`/${DEFAULT_VIEW_PANELS.NEW}/${cat}`);
							} else {
								routeNavigator.back();
							}
						}
						}
					/>
				}
			>
				АО НПФ ЛОГИКА
			</PanelHeader>
			{isVKCOM && (
				<Div style={{textAlign:"center"}}><img src="/logo.svg" style={{maxWidth:"50%", height:"auto", margin:"auto"}}/></Div>
			)}
			{!isVKCOM && (
				<Div><img src="/logo.svg" style={{maxWidth:"100%", height:"auto", margin:"auto"}}/></Div>
				)}
			
			<Group>
					<SelectProduct />
			</Group>
		</Panel>
	);
};
